import ProviderCarousel from 'aac-components/components/ProviderCarousel';
import styled from 'styled-components';
import {useContext, useEffect, useState} from 'react';
import {BREAKPOINT} from 'aac-components/utils/styles';
import AppContext from '../../AppContext';
import Sidebar from '../../Sidebar';
import Link from 'next/link';
import IconLocationOutline from 'aac-components/components/Icons/Basic/IconLocationOutline';
import IconChevron from 'aac-components/components/Icons/Arrow/IconChevron';
import IconMail from 'aac-components/components/Icons/Misc/IconMail';
import IconMessageTyping from 'aac-components/components/Icons/Communication/IconMessageTyping';

const commonsProviderFolder =
    'https://americanaddictioncenters-org.s3.us-west-2.amazonaws.com/common/providers/';
const aacInsurancePageFolder = 'https://americanaddictioncenters.org/insurance-coverage/';

const DefaultTemplateSidebar = () => {
    return (
        <Sidebar>
            <div className="sidebar__cta">
                <div className="sidebar__cta--title">Take The Next Step</div>
                <div className="sidebar__cta--buttons">
                    <Link
                        href="/treatment-centers"
                        title="Find Rehab Near You"
                        style={{
                            textDecoration: 'none',
                            display: 'block',
                            marginBottom: '16px',
                        }}>
                        <button>
                            <IconLocationOutline fill="var(--interactive-300)" />
                            <div>Find Rehab Near You</div>
                            <IconChevron fill="var(--interactive-300)" rotate="-90" />
                        </button>
                    </Link>
                    <Link
                        href="/contact-us"
                        title="Contact Us Today"
                        style={{
                            textDecoration: 'none',
                            display: 'block',
                            marginBottom: '16px',
                        }}>
                        <button>
                            <IconMail fill="var(--interactive-300)" />
                            <div>Contact Us Today</div>
                            <IconChevron fill="var(--interactive-300)" rotate="-90" />
                        </button>
                    </Link>
                    <Link
                        href="/not-ready-to-talk"
                        title="Sign Up For Text Support"
                        style={{
                            textDecoration: 'none',
                            display: 'block',
                        }}>
                        <button>
                            <IconMessageTyping fill="var(--interactive-300)" />
                            <div>Sign Up For Text Support</div>
                            <IconChevron fill="var(--interactive-300)" rotate="-90" />
                        </button>
                    </Link>
                </div>
            </div>

            <div className="sidebar__providers">
                <ProviderCarousel providerCards={providerCards} />
            </div>
            <style jsx>
                {`
                    .sidebar__cta {
                        margin-bottom: 40px;
                    }
                    .sidebar__cta--title {
                        font-weight: bold;
                        margin-bottom: 16px;
                    }
                    .sidebar__cta button {
                        min-width: 100%;
                        padding: 8px;
                        background: var(--interactive-100);
                        border: 2px solid var(--interactive-300);
                        border-radius: 8px;
                        display: grid;
                        grid-template-columns: 24px auto 24px;
                        align-items: center;
                        grid-gap: 8px;
                        text-align: left;
                        font-weight: bold;
                        cursor: pointer;
                        color: var(--gray-500);
                    }
                    .sidebar__providers {
                        margin-bottom: 32px;
                    }
                `}
            </style>
        </Sidebar>
    );
};

export default DefaultTemplateSidebar;

const ButtonInvertStyles = styled.div`
    a:hover > svg {
        filter: brightness(0) invert(1);
        transition: all 0.25s;
    }
`;

const FinancingInquiryFormStyles = styled.div`
    button {
        margin-top: 0;
    }
    .radio {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 8px;
    }
    .financing-inquiry-form__content {
        max-height: fit-content;
        overflow-y: none;
    }
    @media screen and (min-width: ${BREAKPOINT}) {
        .financing-inquiry-form__content {
            max-height: ${(props) => props?.maxHeight}px;
            overflow-y: auto;
        }
    }
    .financing-inquiry-form__content::-webkit-scrollbar {
        width: 5px;
    }

    /* Track */
    .financing-inquiry-form__content::-webkit-scrollbar-track {
        background: var(--gray-100);
        border-radius: 2em;
    }

    /* Handle */
    .financing-inquiry-form__content::-webkit-scrollbar-thumb {
        background: var(--gray-300);
        border-radius: 2em;
    }
    .financing-inquiry-form__content-text {
        margin-bottom: 16px;
    }
    .financing-inquiry-form__buttons {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }
    .primary-action,
    .secondary-action {
        margin: 0 !important;
    }
    .cl-disclaimer__trigger {
        margin-top: 4px;
    }
    #ssvob__loading svg {
        max-height: 50px !important;
    }
    #contact-form svg {
        max-height: 50px !important;
    }
`;

const providerCards = [
    {
        title: 'Aetna',
        image: `${commonsProviderFolder}aetna.png`,
        href: `${aacInsurancePageFolder}aetna`,
    },
    {
        title: 'Anthem',
        image: `${commonsProviderFolder}anthem.png`,
        href: `${aacInsurancePageFolder}anthem`,
    },
    {
        title: 'Cigna',
        image: `${commonsProviderFolder}cigna.png`,
        href: `${aacInsurancePageFolder}cigna-insurance`,
    },
    {
        title: 'United Healthcare',
        image: `${commonsProviderFolder}united-healthcare.png`,
        href: `${aacInsurancePageFolder}unitedhealth-group`,
    },
    {
        title: 'Ambetter',
        image: `${commonsProviderFolder}ambetter.png`,
        href: `${aacInsurancePageFolder}ambetter`,
    },
    {
        title: 'Blue Cross Blue Shield',
        image: `${commonsProviderFolder}blue-cross-blue-shield.png`,
        href: `${aacInsurancePageFolder}blue-cross-blue-shield-association`,
    },
    {
        title: 'Tufts',
        image: `${commonsProviderFolder}tufts.png`,
        href: `${aacInsurancePageFolder}tufts-health-plan`,
    },
    {
        title: 'Magellan Healthcare',
        image: `${commonsProviderFolder}magellan-health.png`,
        href: `${aacInsurancePageFolder}magellan-insurance`,
    },
    {
        title: 'VA Insurance',
        image: `${commonsProviderFolder}va-2.png`,
        href: `${aacInsurancePageFolder}va-benefits`,
    },
    {
        title: 'Humana',
        image: `${commonsProviderFolder}humana.png`,
        href: `${aacInsurancePageFolder}humana`,
    },
    {
        title: 'Carelon Behavioral Health Options',
        image: `${commonsProviderFolder}carelon.svg`,
        href: `${aacInsurancePageFolder}carelon-behavioral-health`,
    },
    {
        title: 'Kaiser Permanente',
        image: `${commonsProviderFolder}kaiser.png`,
        href: `${aacInsurancePageFolder}kaiser-permanente`,
    },
    {
        title: 'Tricare',
        image: `${commonsProviderFolder}tricare.png`,
        href: `${aacInsurancePageFolder}tricare`,
    },
    {
        title: 'Amerigroup',
        image: `${commonsProviderFolder}amerigroup.png`,
        href: `${aacInsurancePageFolder}amerigroup`,
    },
    {
        title: 'AvMed',
        image: `${commonsProviderFolder}avmed.png`,
        href: `${aacInsurancePageFolder}avmed-insurance`,
    },
    {
        title: 'First Health',
        image: `${commonsProviderFolder}first-health-network.png`,
        href: `${aacInsurancePageFolder}first-health`,
    },
    {
        title: 'Emblem Health',
        image: `${commonsProviderFolder}emblem-health.png`,
        href: `${aacInsurancePageFolder}emblemhealth-insurance`,
    },
    {
        title: 'Meritain Insurance',
        image: `${commonsProviderFolder}meritain-health.png`,
        href: `${aacInsurancePageFolder}meritain-insurance`,
    },
    {
        title: 'Empire Blue Cross Blue Shield',
        image: `${commonsProviderFolder}empire-bcbs.png`,
        href: `${aacInsurancePageFolder}empire-blue-cross-blue-shield`,
    },
    {
        title: 'Geisinger',
        image: `${commonsProviderFolder}gesinger.png`,
        href: `${aacInsurancePageFolder}geisinger-insurance`,
    },
    {
        title: 'Harvard Pilgrim',
        image: `${commonsProviderFolder}harvard-pilgrim-health-care.png`,
        href: `${aacInsurancePageFolder}harvard-pilgrim-insurance`,
    },
    {
        title: 'Health Plan of Nevada',
        image: `${commonsProviderFolder}health-plan-nevada-uhc.png	`,
        href: `${aacInsurancePageFolder}health-plan-of-nevada`,
    },
    {
        title: 'MagnaCare',
        image: `${commonsProviderFolder}magnacare.png`,
        href: `${aacInsurancePageFolder}magnacare-insurance`,
    },
    {
        title: 'Managed Health Network (MHN)',
        image: `${commonsProviderFolder}mhn.png`,
        href: `${aacInsurancePageFolder}managed-health-network-mhn`,
    },
    {
        title: 'Molina Healthcare',
        image: `${commonsProviderFolder}molina.png`,
        href: `${aacInsurancePageFolder}molina-healthcare`,
    },
    {
        title: 'New Directions',
        image: `${commonsProviderFolder}new-directions.png`,
        href: `${aacInsurancePageFolder}new-directions`,
    },
    {
        title: 'Optum',
        image: `${commonsProviderFolder}optum.png`,
        href: `${aacInsurancePageFolder}optum`,
    },
    {
        title: 'Oxford Health Plans',
        image: `${commonsProviderFolder}oxford-health-plans.png`,
        href: `${aacInsurancePageFolder}oxford-health-insurance`,
    },
    {
        title: 'Premera BCBS',
        image: `${commonsProviderFolder}premera-bcbs.png`,
        href: `${aacInsurancePageFolder}blue-cross-blue-shield-association/premera`,
    },
    {
        title: 'Providence',
        image: `${commonsProviderFolder}providence.png`,
        href: `${aacInsurancePageFolder}providence-health-plan`,
    },
    {
        title: 'Rocky Mountain HMO',
        image: `${commonsProviderFolder}rocky-mt-health.png`,
        href: `${aacInsurancePageFolder}rocky-mountain-hmo`,
    },
    {
        title: 'Sierra',
        image: `${commonsProviderFolder}sierra.png`,
        href: `${aacInsurancePageFolder}sierra-health-and-life-insurance`,
    },
    {
        title: 'State Farm',
        image: `${commonsProviderFolder}state-farm.png`,
        href: `${aacInsurancePageFolder}state-farm`,
    },
    {
        title: 'Veterans Choice Program',
        image: `${commonsProviderFolder}va.jpg`,
        href: `${aacInsurancePageFolder}veterans-choice-program`,
    },
    {
        title: 'And many more...',
        image: '',
        href: 'https://americanaddictioncenters.org/insurance-providers',
    },
];
